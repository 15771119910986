import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['rim']

  copy(event) {
    const el = event.currentTarget
    const form = el.closest('.clone-form-wrapper')
    let previousEl = form.previousElementSibling

    if (previousEl.type === 'hidden')
      previousEl = previousEl.previousElementSibling

    this.clone(form, previousEl)
  }

  clone(current, prev) {
    const fields =
      'select, input[type="number"], input[type="text"], input[type="checkbox"]'
    const currentFields = [...current.querySelectorAll(fields)]
    const prevFields = [...prev.querySelectorAll(fields)]

    for (let i = 0; prevFields.length > i; i++) {
      const el = prevFields[i]

      if (el.type === 'checkbox') {
        currentFields[i].checked = el.checked
      } else {
        currentFields[i].value = el.value
      }
    }

    const showTreadSelect = [17.5, 19.5, 22.5].includes(
      Number(this.rimTarget.value)
    )

    if (showTreadSelect) {
      window.dispatchEvent(new CustomEvent('show:tread'))
      window.dispatchEvent(new CustomEvent('hide:runflat'))
    } else {
      window.dispatchEvent(new CustomEvent('show:runflat'))
      window.dispatchEvent(new CustomEvent('hide:tread'))
    }
  }
}

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'

// import { linkTrackingFix } from 'link-tracking-fix'

import '@fontsource/red-hat-display/400.css'
import '@fontsource/red-hat-display/500.css'
import '@fontsource/red-hat-display/700.css'
import '@fontsource/dm-sans/400.css'
import '@fontsource/dm-sans/500.css'
import '@fontsource/dm-sans/700.css'

import 'alpinejs'
import 'tailwindcss/tailwind.css'

import 'controllers'

require.context('../images', true)

Rails.start()
ActiveStorage.start()
Turbolinks.start()

// document.addEventListener('turbolinks:load', () => linkTrackingFix())

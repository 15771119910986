import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['mobile', 'desktop', 'input']
  static values = { rim: String }

  initialize() {
    this.sizes = [17.5, 19.5, 22.5]
  }

  connect() {
    this.width = window.innerWidth

    window.addEventListener('resize', () => {
      this.width = window.innerWidth
      this.place()
    })

    this.place()
    this.toggle(this.rimValue)
  }

  listen(event) {
    this.toggle(event.currentTarget.value)
  }

  toggle(value) {
    if (!value) {
      this.hide()
      return
    }
    !this.sizes.includes(Number(value)) ? this.show() : this.hide()
  }

  show() {
    this.desktopTarget.classList.remove('lg:hidden')
    this.mobileTarget.classList.remove('hidden')
  }

  hide() {
    this.desktopTarget.classList.add('lg:hidden')
    this.mobileTarget.classList.add('hidden')
  }

  place() {
    if (this.width > 1024) {
      this.desktopTarget.innerHTML = this.inputTarget.innerHTML
      this.mobileTarget.innerHTML = ''
    } else {
      this.mobileTarget.innerHTML = this.inputTarget.innerHTML
      this.desktopTarget.innerHTML = ''
    }
  }
}

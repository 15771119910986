import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { rim: String }
  static targets = ['input', 'select']

  initialize() {
    this.sizes = [17.5, 19.5, 22.5]
  }

  connect() {
    this.toggle(this.rimValue)
  }

  listen(event) {
    this.toggle(event.currentTarget.value)
  }

  toggle(value) {
    this.sizes.includes(Number(value)) ? this.show() : this.hide()
  }

  show() {
    this.inputTarget.classList.remove('hidden')
  }

  hide() {
    this.inputTarget.classList.add('hidden')
    this.reset()
  }

  reset() {
    this.selectTarget.value = ''
  }
}

import { Controller } from 'stimulus'

import Swiper, { Autoplay, Navigation } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, Navigation])

export default class extends Controller {
  static targets = ['container', 'desktopPrev', 'desktopNext', 'mobilePrev', 'mobileNext']

  connect() {
    this.slider = new Swiper(this.containerTarget, {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 32,
      autoplay: {
        delay: 10000,
      },
      navigation: {
        nextEl: [this.desktopNextTarget, this.mobileNextTarget],
        prevEl: [this.desktopPrevTarget, this.mobilePrevTarget],

      },
      breakpoints: {
        640: {
          slidesPerView: 1.5,
        },
      },
    })
  }
}

import { Controller } from 'stimulus'

import Swiper, { Autoplay, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, Pagination])

export default class extends Controller {
  static targets = ['pagination']

  connect() {
    new Swiper(this.element, {
      slidesPerView: 2,
      spaceBetween: 16,
      loop: true,
      pagination: {
        el: this.paginationTarget,
        clickable: true,
      },
      autoplay: {
        delay: 10000,
      },
    })
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['button', 'sideButton']

  connect() {
    this.changed = false
  }

  listen() {
    this.changed = true
    this.update()
  }

  update() {
    if (this.changed) {
      this.buttonTarget.classList.remove('hidden')
      this.sideButtonTarget.classList.remove('hidden')
    }
  }
}
